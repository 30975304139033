@import '../../../styles/settings.scss';

.contactSection {
    padding: 30px 0;
    text-align: center;
    background-color: $color-gray;
}

.sectionTitle {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: $font-semiBold;
    &::after {
        content: '';
        display: block;
        width: 60px;
        height: 3px;
        background-color: $color-success;
        margin: 10px auto;
    }
}

.contactInfo {
    margin-top: 30px;
}

.contactItem {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $font-regular;
    text-align: left;
    margin-bottom: 10px;
}

.contactIcon {
    font-size: 15px;
    margin-right: 10px;
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .sectionTitle {
        font-size: 2rem;
    }
}

@media (max-width: 576px) {
    .sectionTitle {
        font-size: 1.5rem;
    }
}
