$color-light: #fff;
$color-dark: #000000;
$color-success: #02d169;
$color-gray: #f6f6f6f5;

$font-text: 'Raleway', sans-serif;

$font-regular: 400;
$font-medium: 500;
$font-semiBold: 600;
$font-bold: 700;

$base-size: 12px;