@import '../../../styles/settings.scss';

.navBar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: $color-light;
  padding: 10px 20px;

  @media only screen and (min-width: 768px) {
    padding: 10px 150px;
  }
}

.iconLink {
  font-size: 24px;
  text-decoration: none;
  position: relative;
  font-family: $font-text;
  font-weight: $font-bold;
  color: $color-dark;

  @media only screen and (min-width: 768px) {
    font-size: 30px;
  }

  &::after {
    content: '';
    display: block;
    width: 50%;
    height: 2px;
    background-color: $color-success;
    position: absolute;
    bottom: -3px;
    left: 25%;
  }
}

.navLink {
  cursor: pointer;
  text-decoration: none;
  list-style: none;
  color: $color-dark;
  font-family: $font-text;
  font-weight: $font-regular;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

.linkActive {
  color: $color-success;
}
