@import './settings.scss';

*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $color-light;
  font-family: $font-text;
  font-size: ($base-size * 1.1);
  color: $color-dark;
  letter-spacing: 0.5px;
}

@import "./typography.scss";