@import '../../../styles/settings.scss';

.aboutSection {
    background-color: $color-gray;
    width: 100%;
}

.container {
    padding: 50px 0;
}

.sectionTitle {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: $font-semiBold;
    &::after {
        content: '';
        display: block;
        width: 60px;
        height: 3px;
        background-color: $color-success;
        margin: 10px auto;
    }
}

.card {
    border: 1px solid $color-gray;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease; 
    display: flex;
    flex-direction: column;
    min-height: 100%;
    margin-bottom: 20px;

    &:hover {
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    }
}

.card-body {
    flex-grow: 1;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
}

.icon img {
    max-width: 100px;
    max-height: 100px;
}

.cardTitle {
    color: $color-dark;
    font-weight: $font-medium;
    font-size: 1.5rem;
    margin: 15px;
    text-align: center;
}

.cardText {
    font-weight: $font-regular;
    color: $color-dark;
    font-size: 1rem;
    text-align: center;
}

.descriptionSection {
    padding: 30px 0;
    color: $color-dark;
    font-weight: $font-regular;
    text-align: center;
}

.descriptionSection p {
    font-size: 1rem;
}

@media (max-width: 768px) {
    .descriptionSection {
        padding: 20px 10px;
    }

    .descriptionSection p {
        font-size: 0.9rem;
    }

    .card {
        margin-bottom: 20px;
    }

    .cardTitle {
        font-size: 1.2rem;
    }

    .cardText {
        font-size: 0.9rem;
    }

    .icon img {
        max-width: 80px;
        max-height: 80px;
    }
}

@media (max-width: 576px) {
    .container {
        padding: 20px 0;
    }

    .sectionTitle {
        font-size: 1.5rem;
    }

    .cardTitle {
        font-size: 1rem;
    }

    .cardText {
        font-size: 0.8rem;
    }

    .icon img {
        max-width: 60px;
        max-height: 60px;
    }
}
