@import '../../../styles/settings.scss';

.container {
    padding: 50px;
}

.sectionTitle {
    text-align: center;
    margin-bottom: 30px;
    font-size: 2rem;
    font-weight: $font-semiBold;
    &::after {
        content: '';
        display: block;
        width: 60px;
        height: 3px;
        background-color: $color-success;
        margin: 10px auto;
    }
}

.apartmentGrid {
    display: grid;
    gap: 50px;
}

.imageContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    text-align: center;

    &:nth-child(even) {
        direction: rtl;
    }
}

.apartmentImage {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    align-self: self-end;
}

.apartmentImage:hover {
    transform: scale(1.05);
}

.imageInfo {
    background-color: $color-light;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sectionHeading {
    margin-bottom: 10px;
    font-size: 1.5rem;
    font-weight: $font-medium;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    position: relative;
    max-width: 80vw;
    max-height: 80vh; 
    display: flex;
    justify-content: center;
}

.closeButton {
    position: absolute;
    top: -50px;
    right: 10px;
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
    z-index: 1001;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
}

.modalImage {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table {
    width: 100%;
    border-collapse: collapse;
    background-color: $color-light;
    border-radius: 8px;
    overflow: hidden;
    direction: ltr;
}

.table th,
.table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
}

.table th {
    background-color: #f2f2f2;
}

.table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tbody tr:hover {
    background-color: #f0f0f0;
}

.viewButton {
    background-color: $color-success;
    color: $color-light;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: darken($color-success, 10%);
    }
}

@media only screen and (max-width: 1200px) {
    .apartmentGrid {
        grid-template-columns: 1fr; 
    }

    .imageContainer {
        grid-template-columns: 1fr; 
        direction: ltr; 
    }

    .imageInfo {
        text-align: center; 
    }

    .table {
        margin-top: 20px; 
    }

    .apartmentImage {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 1750px) {
    .apartmentImage {
        align-self: center;
    }
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 20px;
    }

    .sectionTitle {
        font-size: 1.5rem;
    }

    .apartmentGrid {
        gap: 20px;
    }

    .imageContainer {
        grid-template-columns: 1fr; 
        gap: 10px;
    }

    .table {
        font-size: 0.8rem;
    }

    .table th,
    .table td {
        padding: 5px;
    }

    .sectionHeading {
        font-size: 1.2rem;
    }

    .viewButton {
        padding: 3px 6px;
    }

    .apartmentImage {
        margin-top: 10px;
    }
}

@media only screen and (max-width: 450px) {
    .container {
        padding: 10px;
    }

    .sectionTitle {
        font-size: 1.2rem;
    }

    .apartmentGrid {
        gap: 10px;
    }

    .imageContainer {
        grid-template-columns: 1fr;
        gap: 5px;
    }

    .imageInfo {
        padding: 5px;
    }

    .sectionHeading {
        font-size: 1rem;
    }

    .table {
        font-size: 0.7rem;
    }

    .table th,
    .table td {
        padding: 3px;
    }

    .viewButton {
        padding: 2px 4px;
    }

    .apartmentImage {
        margin-top: 5px;
    }
}